.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}

.DayPicker-Day {
  border-radius: 0 !important;
}

.DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.DayPickerInput > input {
  padding: 12px 20px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-sizing: border-box;
}
